import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import SoftwareSectionStyle from "./SoftwareSectionStyle.module.styl"

export default function SoftwareSection() {
  return (
    <div className={SoftwareSectionStyle.container}>
      <div className={SoftwareSectionStyle.content}>
        <h3 className={SoftwareSectionStyle.title}>Build better software</h3>
        <h4 className={SoftwareSectionStyle.subtitle}>
          Seamless collaboration, total transparency, secure delivery. We’ve
          developed a better approach to custom software development.
        </h4>
      </div>

      <div className={SoftwareSectionStyle.btn_holder}>
        <a
          onClick={() => {
            document
              .getElementById("newsletter")
              .scrollIntoView({ behavior: "smooth", block: "start" })
          }}
          className={SoftwareSectionStyle.what_we_do}
        >
          Contact us <FontAwesomeIcon icon={faAngleRight} />
        </a>
      </div>
    </div>
  )
}
