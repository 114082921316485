import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import HomePageTemplate from "../modules/home/HomePage.template"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className='animation'>

    <HomePageTemplate
      taylorSwiftImage={data.taylorSwiftImage.edges}
      homePageBookSectionImage={data.homePageBookSectionImage.edges}
      overADecadeSectionImage={data.overADecadeSectionImage.edges}
    />
    </div>
  </Layout>
)

export default IndexPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    taylorSwiftImage: allFile(
      filter: { relativeDirectory: { eq: "taylorSwiftImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    homePageBookSectionImage: allFile(
      filter: { relativeDirectory: { eq: "homePageBookSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1000,quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }  
     overADecadeSectionImage: allFile(
      filter: { relativeDirectory: { eq: "overADecadeSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 600,quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    } 
  }
`
