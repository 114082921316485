import React, { useState } from "react"
import WorkSectionStyle from "./WorkSectionStyle.module.styl"
import { Link } from "gatsby"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 50,
  },
}

function WorkSection() {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section id="work_section" className={WorkSectionStyle.work_section}>
      <div className={WorkSectionStyle.holder}>
        <h2>Want to work with us?</h2>

        <a
          onClick={() => {
            document
              .getElementById("newsletter")
              .scrollIntoView({ behavior: "smooth", block: "start" })
          }}
        >
          Get in touch
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5.848"
            height="9.647"
            viewBox="0 0 5.848 9.647"
          >
            <path
              className="a"
              d="M4.817,3.5,1.179,0,0,1.229,4.817,5.848l4.83-4.618L8.467,0Z"
              transform="translate(0 9.647) rotate(-90)"
            />
          </svg>
        </a>
      </div>
    </section>
  )
}

export default WorkSection
