import React from "react"
import Image from "gatsby-image"
import BookSectionStyle from "./BookSectionStyle.module.styl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import {Link} from "gatsby"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false

const BookSectionTemplate = ({ homePageBookSectionImage }) => {
  return (
    <section className={BookSectionStyle.book_section}>
    <Image
      className={BookSectionStyle.book_section_image}
      fluid={homePageBookSectionImage[0].node.childImageSharp.fluid}
    />
    <div className={BookSectionStyle.btn_holder}>
      <Link to='/the-book' className={BookSectionStyle.the_book}>
        The Book <FontAwesomeIcon icon={faAngleRight} />
      </Link>
    </div>
    </section>
  )
}

export default BookSectionTemplate
