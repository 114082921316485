import React from "react"
import HeroSection from "./components/heroSection/HeroSection"
import BookSectionTemplate from "./components/bookSection/BookSection.template"
import OverADecadeSection from "./components/overADecadeSection/OverADecadeSection"
import NewsletterSection from "../newsletter/NewsletterSection"
import WorkSection from "../caseStudies/components/workSection/WorkSection"
import SoftwareSection from "./components/softwareSection/SoftwareSection"

const HomePageTemplate = ({
  taylorSwiftImage,
  homePageBookSectionImage,
  overADecadeSectionImage,
}) => {
  return (
    <>
      <HeroSection taylorSwiftImage={taylorSwiftImage} />
      <SoftwareSection />
      <OverADecadeSection overADecadeSectionImage={overADecadeSectionImage} />
      <WorkSection />
      <NewsletterSection />
    </>
  )
}

export default HomePageTemplate

//? left here in case of fallback
// const oldHOmepage = (
//   <>
//     <HeroSection taylorSwiftImage={taylorSwiftImage} />
//     <BookSectionTemplate homePageBookSectionImage={homePageBookSectionImage} />
//     <OverADecadeSection overADecadeSectionImage={overADecadeSectionImage} />
//     <WorkSection />
//     <NewsletterSection />
//   </>
// )
