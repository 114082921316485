import React, { useEffect, useState, createRef } from "react"
import lottie from "lottie-web"
import OverADecadeSectionStyle from "./OverADecadeSectionStyle.module.styl"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import Footer from "../../../../common/footer/Footer"
import { Link } from "gatsby"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import animation from "../../../../assets/images/cross_spin"
import brands from "../../../../assets/images/overADecadeSectionImage/homepage_brands.png"

import hp from "../../../../assets/images/overADecadeSectionImage/hp.png"
import unify from "../../../../assets/images/overADecadeSectionImage/unify.png"
import siemens from "../../../../assets/images/overADecadeSectionImage/siemens.png"
import resolve from "../../../../assets/images/overADecadeSectionImage/resolve.png"
import bulpros from "../../../../assets/images/overADecadeSectionImage/bulpros.png"
import ingram from "../../../../assets/images/overADecadeSectionImage/ingram.png"

config.autoAddCss = false

const data = [
  { url: hp, color: "#fff" },
  { url: unify, color: "#2A3136" },
  { url: siemens, color: "#fff" },
  { url: resolve, color: "#072747" },
  { url: bulpros, color: "#fff" },
  { url: ingram, color: "#016CAE" },
]

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

function OverADecadeSection({ overADecadeSectionImage }) {
  const [isVisible, setSectionVisible] = useState(false)
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => anim.destroy() // optional clean up for unmounting
  }, [])
  let animationContainer = createRef()
  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={OverADecadeSectionStyle.over_a_decade_section}>
      <div className={OverADecadeSectionStyle.image_holder}>
        <div className={OverADecadeSectionStyle.grid}>
          {data?.map((img, key) => (
            <div
              key={key}
              className={OverADecadeSectionStyle.card}
              style={{ background: img.color, order: key + 1 }}
            >
              <img src={img.url} />
            </div>
          ))}
        </div>
        <div
          className={OverADecadeSectionStyle.cross}
          ref={animationContainer}
        />
      </div>

      <div className={OverADecadeSectionStyle.over_a_decade_holder}>
        <h2>Over a decade</h2>

        <div className={OverADecadeSectionStyle.content}>
          of successful project executions for global clients
        </div>

        <div className={OverADecadeSectionStyle.btn_holder}>
          <Link to="/services" className={OverADecadeSectionStyle.explore_btn}>
            View services <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default OverADecadeSection
