import React, { useEffect, useState, createRef } from "react"
import lottie from "lottie-web"
import HeroSectionStyle from "./HeroSectionStyle.module.styl"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Footer from "../../../../common/footer/Footer"
import { Link } from "gatsby"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import animation from "../../../../assets/images/cross_spin"
// import st2 from "../../../../assets/images/st2/st2.png"
import st2 from "../../../../assets/videos/hero.mp4"

config.autoAddCss = false

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

function HeroSection() {
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    const video = document.getElementById("video")

    // setTimeout(() => {
    //   video.style.height = "890px"
    //   video.style.minHeight = "890px"
    // }, 1000)

    return () => anim.destroy() // optional clean up for unmounting
  }, [])
  let animationContainer = createRef()
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={HeroSectionStyle.hero_section}>
      <div className={HeroSectionStyle.three_seconds_section}>
        <h1 className={HeroSectionStyle.title}>
          Think beyond the wave
          {/* <p className={HeroSectionStyle.number}>
            3 <span>seconds.</span>
          </p> */}
        </h1>

        <h3>
          We provide software development and digital services that enable
          organizations to operate, innovate and scale their business
        </h3>

        <div
          onClick={() => {
            document
              .getElementById("newsletter")
              .scrollIntoView({ behavior: "smooth", block: "start" })
          }}
          style={{ cursor: "pointer" }}
          className={HeroSectionStyle.learn_more}
        >
          Start a project <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>

      <div className={HeroSectionStyle.what_we_do_section}>
        <video autoPlay loop muted playsInline>
          <source src={st2} type="video/mp4" />
        </video>

        {/* <img src={st2} className={HeroSectionStyle.image} /> */}
        <div className={HeroSectionStyle.btn_holder}>
          <Link to="/services" className={HeroSectionStyle.what_we_do}>
            View Services <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
        <div className={HeroSectionStyle.cross} ref={animationContainer} />
        <Footer />
      </div>
    </section>
  )
}

export default HeroSection
